import {
  addMissingFactorsAndSort,
  useCashTransactionGroupAddTransaction,
  useCashTransactionGroupConfirmMismatch,
  useCashTransactionGroups,
  useCashTransactionGroupUnconfirmMismatch,
} from '@pflegenavi/frontend/api-nursing-home';
import {
  useFeatureFlag,
  useFeatureFlagEnabled,
} from '@pflegenavi/frontend/feature-flags';
import type { RowAction } from '@pflegenavi/web-components';
import {
  LoadingContainer,
  makeConfig,
  PaginatedTable2,
} from '@pflegenavi/web-components';
import {
  CashTransactionGroupType,
  FeatureFlag,
  FeatureFlagStatus,
} from '@pflegenavi/shared/api';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useMemo, useState } from 'react';
import {
  useFormatDate,
  useFormatTime,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { useTranslation } from 'react-i18next';
import { Container, Stack } from '@mui/material';
import { CashManagementHeaderBreadcrumbs } from './CashOverviewPage';
import { ChangeCashMode } from './components/form/model';
import { generatePath, nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useNavigate } from 'react-router-dom';
import type { CashTransactionGroupRowModel } from './components/groupTable/model';
import { formatCashTransactionGroupRowModel } from './components/groupTable/model';
import { TypeColumn } from './components/groupTable/TypeColumn';
import {
  CashAmountColumn,
  CheckColumn,
  DateColumn,
  InfoColumn,
  LinkAmountColumn,
} from './components/groupTable/Columns';
import { AddCashTransactionModal } from './CashTransactionGroupDetailPage';
import { useTrackStructuredEvent } from '@pflegenavi/frontend/tracking';
import { CashListDropdownView } from './components/CashListDropdown';
import { useCashListTableQuery } from './useCashListTableQuery';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { keepPreviousData } from '@tanstack/react-query';

type ColumnNames =
  | 'type'
  | 'lastUpdate'
  | 'cashAmount'
  | 'linked'
  | 'check'
  | 'info';

type Filters = never;

const useRowCounts = (cashListId: string) => {
  const { data: allRows } = useCashTransactionGroups({
    cashListId,
    pageSize: 1,
    page: 0,
  });
  const { data: depositRows } = useCashTransactionGroups({
    cashListId,
    pageSize: 1,
    page: 0,
    groupType: [
      CashTransactionGroupType.Deposit,
      CashTransactionGroupType.ResidentDeposit,
    ],
  });
  const { data: withdrawalRows } = useCashTransactionGroups({
    cashListId,
    pageSize: 1,
    page: 0,
    groupType: [
      CashTransactionGroupType.ReceiptWithdrawal,
      CashTransactionGroupType.ResidentWithdrawal,
    ],
  });

  const { data: transferRows } = useCashTransactionGroups({
    cashListId,
    pageSize: 1,
    page: 0,
    groupType: [CashTransactionGroupType.Transfer],
  });

  const { data: adjustmentRows } = useCashTransactionGroups({
    cashListId,
    pageSize: 1,
    page: 0,
    groupType: [CashTransactionGroupType.Adjustment],
  });

  return {
    all: allRows?.meta?.totalItems ?? 0,
    deposit: depositRows?.meta?.totalItems ?? 0,
    withdrawal: withdrawalRows?.meta?.totalItems ?? 0,
    transfer: transferRows?.meta?.totalItems ?? 0,
    adjustment: adjustmentRows?.meta?.totalItems ?? 0,
  };
};

interface CashTransactionGroupTableProps {
  cashListId: string;
  setOpen: Dispatch<
    SetStateAction<
      | undefined
      | {
          id: string;
          type: 'withdrawal' | 'deposit';
          cashListId: string;
        }
    >
  >;
}

export const CashTransactionGroupTable: FC<CashTransactionGroupTableProps> = ({
  setOpen,
  cashListId,
}) => {
  const fDate = useFormatDate();
  const fTime = useFormatTime();
  const { fCurrency } = useFormatting();
  const { t } = useTranslation();

  const hasAccountingPaymentsNew = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );

  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState<
    CashTransactionGroupType | 'all'
  >('all');

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const rowCount = useRowCounts(cashListId);

  const transferEnabled =
    useFeatureFlag(FeatureFlag.AccountingPayments) ===
    FeatureFlagStatus.Enabled;

  const { data: rows, isLoading } = useCashTransactionGroups(
    {
      cashListId,
      pageSize,
      page,
      sort: 'update_date',
      sortOrder: 'desc',
      fields: [
        'residents',
        'transfer_count',
        'cash_changed',
        'amount_linked',
        'notes',
      ],
      groupType:
        selectedType === 'all'
          ? undefined
          : selectedType === CashTransactionGroupType.Deposit
          ? [
              CashTransactionGroupType.Deposit,
              CashTransactionGroupType.ResidentDeposit,
            ]
          : selectedType === CashTransactionGroupType.ReceiptWithdrawal
          ? [
              CashTransactionGroupType.ReceiptWithdrawal,
              CashTransactionGroupType.ResidentWithdrawal,
            ]
          : [selectedType],
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const transformed = useMemo(() => {
    return (
      rows?.data.map((cashTransactionGroup) =>
        formatCashTransactionGroupRowModel(cashTransactionGroup, {
          fDate,
          fTime,
          fCurrency,
          t,
        })
      ) ?? []
    );
  }, [rows, fDate, fTime, fCurrency, t]);

  const { mutateAsync: confirmMismatch } =
    useCashTransactionGroupConfirmMismatch();
  const { mutateAsync: unconfirmMismatch } =
    useCashTransactionGroupUnconfirmMismatch();

  const options = useMemo(() => {
    return {
      items: (row: CashTransactionGroupRowModel) => {
        let mismatchItems: Array<RowAction<CashTransactionGroupRowModel>> = [];
        if (row.mismatchConfirmation) {
          mismatchItems = [
            {
              key: 'mismatch',
              label: t('cashManagement.table-row.unconfirm'),
              onClick: () =>
                unconfirmMismatch({ cashTransactionGroupId: row.id }),
            },
          ];
        } else if (
          row.type !== CashTransactionGroupType.Transfer &&
          row.type !== CashTransactionGroupType.Deposit &&
          row.type !== CashTransactionGroupType.Adjustment
        ) {
          mismatchItems = [
            {
              key: 'mismatch',
              label: t('cashManagement.table-row.confirm'),
              onClick: () =>
                confirmMismatch({ cashTransactionGroupId: row.id }),
            },
          ];
        }

        if (
          row.type === CashTransactionGroupType.Deposit ||
          row.type === CashTransactionGroupType.ResidentDeposit ||
          row.type === CashTransactionGroupType.Transfer
        ) {
          return mismatchItems;
        }
        return [
          ...mismatchItems,
          {
            label: t('cashManagement.table-row.deposit'),
            onClick: () => {
              setOpen({
                id: row.id,
                type: 'deposit',
                cashListId: row.cashListId,
              });
            },
          },
          {
            label: t('cashManagement.table-row.withdrawal'),
            onClick: () => {
              setOpen({
                id: row.id,
                type: 'withdrawal',
                cashListId: row.cashListId,
              });
            },
          },
        ];
      },
    };
  }, [confirmMismatch, setOpen, t, unconfirmMismatch]);

  const groupDetailPath = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  )
    ? nhAppMainPages.NURSING_HOME_ACCOUNTING_GROUP_DETAIL
    : nhAppMainPages.CASH_MANAGEMENT_GROUP_DETAIL;

  const recentCashTransactionGroupsConfig = useMemo(
    () =>
      makeConfig<CashTransactionGroupRowModel, ColumnNames, Filters>({
        showAll: undefined,
        onClickRow: (row) => {
          navigate(
            generatePath(groupDetailPath, {
              groupId: row.id,
            })
          );
        },
        tabs: {
          selectedItem: selectedType,
          updateFilterModel: setSelectedType,
          items: [
            {
              value: 'all',
              label: t('receiptType.all'),
              color: 'default',
              count: rowCount.all,
            },
            {
              value: CashTransactionGroupType.Deposit,
              label: t('cashManagement.deposit'),
              color: 'success',
              count: rowCount.deposit,
            },
            {
              value: CashTransactionGroupType.ReceiptWithdrawal,
              label: t('cashManagement.withdrawal'),
              color: 'error',
              count: rowCount.withdrawal,
            },
            ...(transferEnabled
              ? [
                  {
                    value: CashTransactionGroupType.Transfer,
                    label: t('cashManagement.link.table-row.type.transfer'),
                    color: 'default',
                    count: rowCount.transfer,
                  } as const,
                ]
              : []),
            {
              value: CashTransactionGroupType.Adjustment,
              label: t('cashManagement.table-row.adjustment'),
              color: 'warning',
              count: rowCount.adjustment,
            },
          ],
        },
        columns: [
          {
            key: 'type',
            label: t('cashManagement.link.table-header.type'),
            align: 'left',
            // @ts-expect-error // type mismatch
            component: TypeColumn,
          },
          {
            key: 'lastUpdate',
            label: t('cashManagement.link.table-header.lastUpdate'),
            // @ts-expect-error // type mismatch
            component: DateColumn,
          },
          {
            key: 'cashAmount',
            label: hasAccountingPaymentsNew
              ? t('cashManagement.link.table-header.amount')
              : t('cashManagement.link.table-header.cashAmount'),
            // @ts-expect-error // type mismatch
            component: CashAmountColumn,
          },
          {
            key: 'linked',
            label: t('cashManagement.link.table-header.linked'),
            // @ts-expect-error // type mismatch
            component: LinkAmountColumn,
          },
          {
            key: 'check',
            label: t('cashManagement.link.table-header.check'),
            // @ts-expect-error // type mismatch
            component: CheckColumn,
            align: 'center',
          },
          {
            key: 'info',
            label: t('cashManagement.link.table-header.info'),
            // @ts-expect-error // type mismatch
            component: InfoColumn,
          },
        ],
        pagination: {
          page,
          pageSize,
          pageSizeOptions: [5, 10],
          total: rows?.meta.totalItems || 0,
          onPageChange: setPage,
          onPageSizeChange: setPageSize,
        },
        options,
      }),
    [
      options,
      page,
      pageSize,
      rows?.meta.totalItems,
      rowCount.adjustment,
      rowCount.transfer,
      rowCount.withdrawal,
      rowCount.deposit,
      selectedType,
      t,
      navigate,
      rowCount.all,
      transferEnabled,
      groupDetailPath,
      hasAccountingPaymentsNew,
    ]
  );

  return (
    <PaginatedTable2
      name="CashTransactionGroupOverview"
      useTrackStructuredEvent={useTrackStructuredEvent}
      config={recentCashTransactionGroupsConfig}
      rows={transformed}
      isLoading={isLoading}
    />
  );
};

const CashOverviewPageV3: FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<
    | undefined
    | {
        id: string;
        type: 'withdrawal' | 'deposit';
        cashListId: string;
      }
  >(undefined);

  const { cashListId, setCashListId, cashLists } = useCashListTableQuery();
  const { selectedNursingHome } = useNursingHomeContext();

  const { mutateAsync: addTransaction } = useCashTransactionGroupAddTransaction(
    selectedNursingHome?.id
  );

  const hasAccountingPaymentsNew = useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );
  const pageTitle = hasAccountingPaymentsNew
    ? t('accounting.nav.transaction-groups')
    : t('nav.cash-management.overview');

  if (!cashListId) {
    return (
      <Container maxWidth={'lg'}>
        <CashManagementHeaderBreadcrumbs pageTitle={pageTitle} />
        <LoadingContainer />
      </Container>
    );
  }

  return (
    <Container maxWidth={'lg'}>
      <CashManagementHeaderBreadcrumbs pageTitle={pageTitle} />

      <Stack direction="column" gap={3}>
        {hasAccountingPaymentsNew && (
          <CashListDropdownView
            selectedCashListId={cashListId}
            setSelectedCashListId={setCashListId}
            cashLists={cashLists}
          />
        )}
        <CashTransactionGroupTable cashListId={cashListId} setOpen={setOpen} />

        <AddCashTransactionModal
          cashListId={open?.cashListId ?? ''}
          open={Boolean(open)}
          onClose={() => setOpen(undefined)}
          type={
            open?.type === 'withdrawal'
              ? ChangeCashMode.Withdraw
              : ChangeCashMode.Deposit
          }
          onAddCashTransactionGroup={(
            type,
            coins,
            bankAccountAmount,
            notes
          ) => {
            if (!open) {
              return;
            }
            const coinsSorted =
              coins !== undefined && coins.length > 0
                ? addMissingFactorsAndSort(coins).map((coin) => coin.amount)
                : undefined;
            addTransaction({
              cashTransactionGroupId: open.id,
              type: type === ChangeCashMode.Withdraw ? 'withdrawal' : 'deposit',
              coins: coinsSorted,
              bankAccountAmount: bankAccountAmount,
              notes,
            });
          }}
        />
      </Stack>
    </Container>
  );
};

export default CashOverviewPageV3;
