import 'reflect-metadata';
import './sentry';
import {
  ScrollToTop,
  SnackbarProvider,
  ThemeProvider,
} from '@pflegenavi/web-components';
import type { FC, PropsWithChildren } from 'react';
import React, { StrictMode, useLayoutEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import type { BrowserHistory } from 'history';
import { createBrowserHistory } from 'history';

import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import App from './app/App';
import {
  AnalyticsApiProvider,
  CashListConfigurationProvider,
  CashManagementApiProvider,
  CashManagementPhoenixApiProvider,
  EmployeesPhoenixApiProvider,
  ImageApiProvider,
  NursingHomePhoenixApiProvider,
  PaymentApiProvider,
  PaymentPhoenixApiProvider,
  ProfileTypeContext,
  ReceiptBatchPhoenixApiProvider,
  ReceiptsPhoenixApiProvider,
  RecurringItemPhoenixApiProvider,
  ReportingApiProvider,
  ReportingPhoenixApiProvider,
  ResidentApiProvider,
  ResidentPhoenixApiProvider,
  ServiceProviderApiProvider,
  SettlementPhoenixApiProvider,
  TransactionApiProvider,
  useGetPaymentProcessor,
  UserProfileApiProvider,
  useUserProfileWithContext,
} from '@pflegenavi/frontend/api-nursing-home';
import { TenantContextProvider } from '@pflegenavi/frontend/tenant';
import { initI18N, LocaleProvider } from '@pflegenavi/frontend/localization';
import {
  localeStorage,
  LocalizationProvider,
  ThemeLocalization,
} from '@pflegenavi/frontend/localization-web-app';
import { AuthenticationProvider } from '@pflegenavi/frontend/authentication';
import {
  PflegenaviAuthenticationProvider,
  shouldUseNewLogin,
  useAuthentication,
} from '@pflegenavi/shared-frontend/authentication';
import ErrorBoundary from './app/components/ErrorBoundry';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { login } from './keycloak';
import { ExtensionProvider } from '@pflegenavi/frontend/layout';
import { useCashManagementNavConfig } from '@pflegenavi/frontend/pages/cash-management';
import { useReportingNavConfig } from '@pflegenavi/frontend/pages/reporting';
import { useRecurringItemsNavConfig } from '@pflegenavi/frontend/pages/residents';
import { useServiceProviderNavConfig } from '@pflegenavi/frontend/pages/service-provider';
import * as Sentry from '@sentry/react';

// this is a virtual font file from vite pluging see: https://github.com/stafyniaksacha/vite-plugin-fonts
import 'unfonts.css';
import { useNursingHomeNavConfig } from '@pflegenavi/frontend/pages/nursing-home';
import { useNursingHomeAccountingNavConfig } from '@pflegenavi/frontend/pages/accountingPayments';
import {
  NursingHomeProvider,
  RedirectUrlProvider,
  useNursingHomeContext,
} from '@pflegenavi/frontend/nursing-home-context';
import {
  FeatureFlagDevProvider,
  useFeatureFlagEnabled,
} from '@pflegenavi/frontend/feature-flags';
import { SnowplowTracking } from '@pflegenavi/frontend/tracking/web';
import { TrackingProvider } from '@pflegenavi/frontend/tracking';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { ClientStoredResourcesProvider } from '@pflegenavi/frontend/client-stored-resources-context';
import { FeatureFlag } from '@pflegenavi/shared/api';
import { PhoenixProvider } from '@pflegenavi/shared-frontend/phoenix';
import { getWebWelcomeToken } from '@pflegenavi/shared/utils';
import { getPhoenixApiBaseUrl } from '@pflegenavi/shared-frontend/platform';

window.global ||= window;

function BrowserRouter({ children }: PropsWithChildren): JSX.Element {
  const ref = useRef<BrowserHistory | undefined>(undefined);

  if (!ref.current) {
    ref.current = createBrowserHistory({
      window,
    });
    // @ts-expect-error // reactHistory is untyped
    window.global.reactHistory = ref.current;
  }

  const history = ref.current;
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setState), [history]);
  return (
    <Router
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
}

const tracker = new SnowplowTracking();
tracker.initialize({
  appId: 'nursing-home-web',
});

// @ts-expect-error // pflegenaviApplication is untyped
window.global.pflegenaviApplication = 'nursing-home-web';
// @ts-expect-error // pflegenaviApplicationVersion is untyped
window.global.pflegenaviApplicationVersion = SENTRY_RELEASE.id;
await initI18N(localeStorage);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 1000 * 60 * 5, // 5 minutes
    },
    mutations: {
      onError: (error, variables, context) => {
        Sentry.captureException(error);
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      Sentry.captureException(error);
    },
  }),
});

const NavExtension: FC<PropsWithChildren> = ({ children }) => {
  // The actual nav extension will only be fetched when we fetched the user profile once. Otherwise every feature flag check will fetch the employee profile endpoint
  // TODO: We need to refactor this.

  const { isPending } = useUserProfileWithContext({});

  if (isPending) {
    return children;
  }

  return <NavExtensionInner>{children}</NavExtensionInner>;
};

const NavExtensionInner: FC<PropsWithChildren> = ({ children }) => {
  const includeCashManagement = !useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );
  const paymentProcessor = useGetPaymentProcessor();
  const nursingHomeNavConfig = useNursingHomeNavConfig();
  const recurringItemsNavConfig = useRecurringItemsNavConfig();
  const cashManagementNavConfig = useCashManagementNavConfig();
  const nursingHomeAccountingNavConfig = useNursingHomeAccountingNavConfig();
  const reportingNavConfig = useReportingNavConfig();
  const serviceProviderNavConfig = useServiceProviderNavConfig();
  return (
    <ExtensionProvider
      value={[
        recurringItemsNavConfig,
        serviceProviderNavConfig,
        nursingHomeAccountingNavConfig,
        ...(includeCashManagement ? [cashManagementNavConfig] : []),
        ...(paymentProcessor === 'stripe' ? [reportingNavConfig] : []),
        nursingHomeNavConfig,
      ]}
    >
      {children}
    </ExtensionProvider>
  );
};

const container = document.getElementById('root');

const root = createRoot(container!);

const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuthentication(true);

  return (
    <AuthenticationProvider
      keycloak={auth}
      login={login}
      redirectUri={`${window.location.origin}/${nhAppMainPages.DASHBOARD}`}
      isNewAuth={Boolean(auth)}
    >
      {children}
    </AuthenticationProvider>
  );
};

function redirectIfNecessary(url: string) {
  const hostname = new URL(url).hostname;

  if (hostname !== window.location.hostname) {
    window.location.replace(url);
    return 'redirected';
  }
  return undefined;
}

const getQueryParam = (param: string) => {
  return new URLSearchParams(window.location.search).get(param);
};

root.render(
  <ClientStoredResourcesProvider storage={localStorage}>
    <PflegenaviAuthenticationProvider
      storage={localStorage}
      getWelcomeToken={getWebWelcomeToken}
      shouldUseNewLogin={shouldUseNewLogin()}
      apiUrl={getPhoenixApiBaseUrl(undefined)}
      redirectIfNecessary={redirectIfNecessary}
      onLogout={() => queryClient.clear()}
    >
      <AuthWrapper>
        <StrictMode>
          <BrowserRouter>
            <SnackbarProvider>
              <QueryClientProvider client={queryClient}>
                <TenantContextProvider>
                  <NursingHomeProvider getQueryParam={getQueryParam}>
                    <RedirectUrlProvider>
                      <ServiceProviderApiProvider>
                        <TransactionApiProvider>
                          <ResidentApiProvider>
                            <ResidentPhoenixApiProvider>
                              <ImageApiProvider>
                                <AnalyticsApiProvider>
                                  <ProfileTypeContext.Provider value="employee">
                                    <EmployeesPhoenixApiProvider>
                                      <UserProfileApiProvider>
                                        <CashManagementApiProvider>
                                          <CashManagementPhoenixApiProvider>
                                            <SettlementPhoenixApiProvider>
                                              <CashListConfigurationProvider
                                                useNursingHomeContext={
                                                  useNursingHomeContext
                                                }
                                              >
                                                <ReportingApiProvider>
                                                  <ReportingPhoenixApiProvider>
                                                    <ReceiptBatchPhoenixApiProvider>
                                                      <RecurringItemPhoenixApiProvider>
                                                        <FeatureFlagDevProvider>
                                                          <ReceiptsPhoenixApiProvider>
                                                            <NursingHomePhoenixApiProvider>
                                                              <PhoenixProvider>
                                                                <NavExtension>
                                                                  <ThemeProvider>
                                                                    <LocaleProvider
                                                                      localeStorage={
                                                                        localeStorage
                                                                      }
                                                                    >
                                                                      <LocalizationProvider>
                                                                        <ThemeLocalization>
                                                                          <PaymentApiProvider>
                                                                            <PaymentPhoenixApiProvider>
                                                                              <DndProvider
                                                                                backend={
                                                                                  HTML5Backend
                                                                                }
                                                                              >
                                                                                <ScrollToTop />
                                                                                <ErrorBoundary>
                                                                                  <TrackingProvider
                                                                                    tracker={
                                                                                      tracker
                                                                                    }
                                                                                  >
                                                                                    <App />
                                                                                  </TrackingProvider>
                                                                                </ErrorBoundary>
                                                                              </DndProvider>
                                                                            </PaymentPhoenixApiProvider>
                                                                          </PaymentApiProvider>
                                                                        </ThemeLocalization>
                                                                      </LocalizationProvider>
                                                                    </LocaleProvider>
                                                                  </ThemeProvider>
                                                                </NavExtension>
                                                              </PhoenixProvider>
                                                            </NursingHomePhoenixApiProvider>
                                                          </ReceiptsPhoenixApiProvider>
                                                        </FeatureFlagDevProvider>
                                                      </RecurringItemPhoenixApiProvider>
                                                    </ReceiptBatchPhoenixApiProvider>
                                                  </ReportingPhoenixApiProvider>
                                                </ReportingApiProvider>
                                              </CashListConfigurationProvider>
                                            </SettlementPhoenixApiProvider>
                                          </CashManagementPhoenixApiProvider>
                                        </CashManagementApiProvider>
                                      </UserProfileApiProvider>
                                    </EmployeesPhoenixApiProvider>
                                  </ProfileTypeContext.Provider>
                                  <ReactQueryDevtools initialIsOpen={false} />
                                </AnalyticsApiProvider>
                              </ImageApiProvider>
                            </ResidentPhoenixApiProvider>
                          </ResidentApiProvider>
                        </TransactionApiProvider>
                      </ServiceProviderApiProvider>
                    </RedirectUrlProvider>
                  </NursingHomeProvider>
                </TenantContextProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </StrictMode>
      </AuthWrapper>
    </PflegenaviAuthenticationProvider>
  </ClientStoredResourcesProvider>
);
