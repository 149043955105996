import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import type { CashList } from '@pflegenavi/frontend/api-nursing-home';
import {
  useAllResidentBalance,
  useCashListConfiguration,
  useGetFinancePhoenix,
} from '@pflegenavi/frontend/api-nursing-home';
import { CashListStorageType } from '@pflegenavi/shared/api';

interface useNursingHomeQuickOverviewResultMangopay {
  isLoading: boolean;
  totalResidentsBalance: number;
  bankAccountCashLists: CashList[];
  cashAccountCashLists: CashList[];
  nursingHomeWalletAmount: number;
}

export const useNursingHomeQuickOverviewMangopay =
  (): useNursingHomeQuickOverviewResultMangopay => {
    const { selectedNursingHome } = useNursingHomeContext();

    const { data: allResidentBalance, isPending: isAllResidentBalanceLoading } =
      useAllResidentBalance(selectedNursingHome?.id);

    const { data, isLoading } = useGetFinancePhoenix({
      nursingHomeId: selectedNursingHome?.id,
    });

    const cashListConfiguration = useCashListConfiguration();

    const bankAccountCashLists = cashListConfiguration.cashLists.filter(
      (cashList) =>
        cashList && cashList.storageType === CashListStorageType.BankAccount
    );
    // Note that the deprecated CashListStorageType.CashOrBankAccount will count as a cashAccountCashList here.
    // For the cashAccountAmount and bankAccountAmount values, the bank and cash parts of CashListStorageType.CashOrBankAccount are counted separately.
    const cashAccountCashLists = cashListConfiguration.cashLists.filter(
      (cashList) => cashList.storageType & CashListStorageType.Cash
    );

    const totalResidentsBalance = allResidentBalance?.balanceInCents ?? 0;

    const nursingHomeWalletAmount = data?.data?.mangopay?.wallet ?? 0;
    return {
      isLoading: isAllResidentBalanceLoading || isLoading,
      totalResidentsBalance,
      nursingHomeWalletAmount,
      bankAccountCashLists,
      cashAccountCashLists,
    };
  };
