import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Card, Stack, styled, TextField, Typography } from '@mui/material';
import { Button, Iconify, Modal, ModalInner } from '@pflegenavi/web-components';
import { DirectDebitStatus } from '@pflegenavi/shared/api';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useDeleteDirectDebitMandateForNursingHome } from '@pflegenavi/frontend/api-nursing-home';

import { useSnackbar } from 'notistack';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';

interface CancelDirectDebitProps {
  status: DirectDebitStatus;
}

export const CancelDirectDebit: FC<CancelDirectDebitProps> = ({ status }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const renderCancelContent = useMemo(() => {
    if (status === DirectDebitStatus.Pending) {
      return (
        <>
          <Typography variant="subtitle2" fontWeight={600} gutterBottom>
            {t('nursing-home.direct-debit-being-setup')}
          </Typography>
          <Typography variant="subtitle2" fontWeight={600} gutterBottom>
            {t('nursing-home.direct-debit-setup-will-inform-you')}
          </Typography>
          <Typography variant="subtitle2" fontWeight={600} gutterBottom>
            {t('nursing-home.direct-debit-want-to-cancel')}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography variant="subtitle2" fontWeight={600} gutterBottom>
          {t('nursing-home.direct-debit-ready-to-use')}
        </Typography>
        <Typography variant="subtitle2" fontWeight={600} gutterBottom>
          {t('nursing-home.direct-debit-want-to-cancel')}
        </Typography>
      </>
    );
  }, [status, t]);

  if (status === DirectDebitStatus.Inactive) {
    return null;
  }

  return (
    <RootContainer>
      <Typography fontWeight={700} sx={{ ml: 1 }} gutterBottom>
        {t('nursing-home.status-update')}
      </Typography>
      <ContentContainer alignItems="stretch" spacing={2}>
        {renderCancelContent}

        <Stack alignItems="flex-end">
          <CancelModal open={openModal} handleClose={handleModalClose} />
          <Button
            onClick={handleModalOpen}
            variant="contained"
            color="error"
            endIcon={<Iconify icon="bx:x" color="white" />}
          >
            {t('direct-debit.cancel')}
          </Button>
        </Stack>
      </ContentContainer>
    </RootContainer>
  );
};

const CancelModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const [msg, setMsg] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onDeleteMandateSuccess = useCallback(() => {
    enqueueSnackbar(t('nursing-home.mandate-deletion-success'), {
      variant: 'success',
    });
    handleClose();
  }, [enqueueSnackbar, handleClose, t]);

  const onDeleteMandateError = useCallback(() => {
    enqueueSnackbar(t('nursing-home.mandate-deletion-failure'), {
      variant: 'error',
    });
  }, [enqueueSnackbar, t]);

  const { selectedNursingHome } = useNursingHomeContext();

  const {
    mutateAsync: handleDirectDebitCancel,
    isPending,
    isSuccess,
  } = useDeleteDirectDebitMandateForNursingHome(
    selectedNursingHome?.id,
    onDeleteMandateSuccess,
    onDeleteMandateError
  );

  const handleSubmitRequest = async () => {
    if (selectedNursingHome?.id) {
      await handleDirectDebitCancel({ nursingHomeId: selectedNursingHome?.id });
    }
  };
  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalInner>
        <CancelModalContainer>
          {!isSuccess ? (
            <>
              <Typography color="primary" fontWeight={700} sx={{ mb: 1 }}>
                {t('direct-debit.cancellation-request')}
              </Typography>
              <Stack>
                <Typography color="grey.800" fontSize={16} sx={{ mb: 0.2 }}>
                  {t('direct-debit.reason-of-cancellation')}
                </Typography>
                <TextField
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  placeholder={t('direct-debit.cancel-eg-reason')}
                  multiline
                  rows={4}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                gap={1}
                sx={{ mt: 2 }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  {t('direct-debit.I-do-not-want-to-cancel')}
                </Button>
                <LoadingButton
                  variant="contained"
                  color="error"
                  loading={isPending}
                  onClick={handleSubmitRequest}
                >
                  {t('direct-debit.submit-cancellation-request')}
                </LoadingButton>
              </Stack>
            </>
          ) : (
            <>
              <Typography color="success" fontWeight={600} sx={{ mb: 2 }}>
                {t('direct-debit.successfully-cancelled-request')}
              </Typography>
              <Typography color="info" fontWeight={600} sx={{ mb: 1 }}>
                {t('direct-debit.nursing-home-will-contact-you')}
              </Typography>
            </>
          )}
        </CancelModalContainer>
      </ModalInner>
    </Modal>
  );
};

const RootContainer = styled(Card)(({ theme }) =>
  theme.unstable_sx({
    p: 1,
    pt: 2,
    width: '100%',
    borderRadius: 3,
  })
);

const ContentContainer = styled(Stack)(({ theme }) =>
  theme.unstable_sx({
    bgcolor: 'grey.200',
    paddingX: 2,
    paddingY: 3,
    pb: 2,
    borderRadius: 3,
  })
);

const CancelModalContainer = styled(Stack)(({ theme }) =>
  theme.unstable_sx({
    width: 450,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 2,
  })
);
